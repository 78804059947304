import React, { Component } from "react";

export const SCROLL_INTERVAL_SIZE = 500;

const withScrollWatcher = (Child) => {
  return class WatchScroll extends Component {
    constructor(props) {
      super(props);
      this.state = { scrollPosition: 0 };
      if (typeof window !== "undefined") {
        this.state.scrollPosition = window.scrollY;
      }
    }
    componentDidMount() {
      window.addEventListener("scroll", this.handleScroll);
    }
    componentWillUnmount() {
      window.removeEventListener("scroll", this.handleScroll);
    }
    handleScroll = () => {
      this.setState({ scrollPosition: window.scrollY });
    };
    render() {
      return (
        <Child
          {...this.props}
          scrollWatcher={{
            documentHeight: typeof window !== "undefined" ? document.body.clientHeight : 0,
            scrollInterval: Math.floor(this.state.scrollPosition / SCROLL_INTERVAL_SIZE),
            scrollIntervals:
              typeof window !== "undefined" ? Math.floor(document.body.clientHeight / SCROLL_INTERVAL_SIZE) : 0,
            scrollIntervalSize: SCROLL_INTERVAL_SIZE,
            scrollPosition: this.state.scrollPosition,
          }}
        />
      );
    }
  };
};

export default withScrollWatcher;
