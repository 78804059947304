import Link from "gatsby-link";
import { graphql } from "gatsby";
import { get, kebabCase, orderBy } from "lodash";
import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { A, Button, H2, H4, P, theme } from "@upsolve/ui";
import { EVENTS } from "@upsolve/shared";
import { track } from "../components/analytics/track";
import * as CONTENT_GROUPS from "../components/analytics/contentGroups";
import MainFooter from "../components/Layout/Footer";
import Header from "../components/Layout/Header";
import Portal from "../components/overlays/Portal";
import SEO from "../components/SEO/SEO";
import TopLevelLayout from "../components/Layout/TopLevelLayout";
import UpsolveSearchBar from "../components/search/UpsolveSearchBar";
import { learnTagsSortedByPriority } from "../components/Learn/learnTagPriority";
import withScrollWatcher from "../components/analytics/withScrollWatcher";
import CSSInjector from "../components/Layout/CSSInjector";
import BlobTablePapers from "../../static/images/blobs/tablePapers.svg";
import WaveSVG from "../../static/images/illustrations/wave.svg";
import { CTATarget } from "../components/CTA/getCTAData";

const contentGroup = CONTENT_GROUPS.LANDING_PAGE;
const templateProps = {
  pageComponentName: "LearnPage",
  pageComponentVersion: 2,
};
const trackingPageContext = { contentGroup, ...templateProps };

/**
 * LearnPage
 * - v0: Single column article list
 * - v1: Short page with directory grid
 * - v2: Add CTAs for screener, FB group, and find a lawyer
 *
 * @component
 * @version 2
 */
const LearnPage = (props) => {
  // Props
  const { data, scrollWatcher } = props;
  const tags = data.tags.nodes;

  // Tracking
  useEffect(() => {
    track(EVENTS.PAGE_VIEW, { pageContext: trackingPageContext });
  }, []);
  const [scrollIntervalCache, setScrollIntervalCache] = useState([0]);
  useEffect(() => {
    if (Math.max(...scrollIntervalCache) < scrollWatcher.scrollInterval) {
      setScrollIntervalCache(scrollIntervalCache.concat(scrollWatcher.scrollInterval));
    }
  }, [scrollWatcher.scrollInterval]);

  // Comp
  return (
    <TopLevelLayout persistentCta="Chat with an Expert for Free">
      <div className="index-container">
        <SEO
          title="Learning Center | Upsolve"
          description="Get answers to all of your questions about bankruptcy. We answer common questions such as, “Should I file for bankruptcy?” and “Will I be able to keep my property during bankruptcy?”"
          image="https://upsolve.org/images/meta-legalaid.png"
          url="https://upsolve.org/learn/"
          jsonLD={[
            {
              "@context": "http://schema.org",
              "@type": "BreadcrumbList",
              itemListElement: [
                {
                  "@type": "ListItem",
                  position: 1,
                  name: "Learn",
                  item: "https://upsolve.org/learn/",
                },
              ],
            },
          ]}
        />
        <CSSInjector
          injectCSS={`body { background: linear-gradient(0deg, ${theme.colors.brand[100]}, ${theme.colors.brand[300]}); }`}
        />
        <Header trackingPageContext={trackingPageContext} ctaTarget={CTATarget.DEBT_TRIAGE} />
        <main>
          <StyledLearnPage>
            <div className="learn__image">
              <BlobTablePapers />
            </div>
            <div className="learn__header">
              <H2 as="h1">Learning Center</H2>
              <P>
          Our nonprofit helps you resolve your debt with free debt relief tools and financial education.
              </P>
          
            </div>
            <div className="learn__transition_waves">
              <WaveSVG />
              <WaveSVG />
            </div>
            <div className="learn__listing">
              <StyledBKTags>
                <UpsolveSearchBar />
                <br />
                <H4>Ready for more? Explore by topic:</H4>
                <div className="bk-tags__category-grid">
                  {orderBy(
                    tags,
                    [(t) => Object.values(learnTagsSortedByPriority).indexOf(t.value)],
                    ["asc"]
                  ).map((tag) => (
                    <Link key={kebabCase(tag.value)} to={`/learn/category/${kebabCase(tag.value)}/`}>
                      <div className="topic-tile">
                        <div className="topic-tile__label">
                          <h6>{tag.label} ➜</h6>
                        </div>
                        <div className="topic-tile__description">
                          {get(tag, "descriptionShort.descriptionShort") || ""}
                        </div>
                      </div>
                    </Link>
                  ))}
                </div>
              </StyledBKTags>
            </div>
          </StyledLearnPage>
        </main>
        <MainFooter />
      </div>
   
    </TopLevelLayout>
  );
};

const StyledBKTags = styled.section`
  background: ${(props) => props.theme.colors.white[800]};
  h4 {
    text-align: center;
    margin-bottom: 1em;
  }
  .bk-tags__category-grid {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 20px;
    max-width: 1180px;
    margin: 0 auto;
    @media (max-width: 45em) {
      grid-template-columns: repeat(1, 1fr);
      max-width: 100%;
      width: 100%;
    }
  }
  .topic-tile {
    &,
    &__label {
      transition: 500ms;
    }
    cursor:pointer;
    border: 1px solid ${(props) => props.theme.colors.white[500]};
    border-radius: 16px;
    overflow: hidden;
    box-shadow: ${(props) => props.theme.effects.shadow[300]};
    &__label {
      padding: 0.5em 0.75em;
      color: ${(props) => props.theme.colors.brand[500]};
      font-size: 32px;
      h6 {
        margin: 0;
        padding: 0;
      }
    }
    &__description {
      padding: 0em 1.5em 2em;
      color: ${(props) => props.theme.colors.gray[700]};
      font-size: 15px;
    }
    &:hover {
      box-shadow: ${(props) => props.theme.effects.shadow[350]};
      border: 1px solid ${(props) => props.theme.colors.brand[300]};
    }
  }
`;


const StyledLearnPage = styled.div`
  .learn__header {
    position: relative;
    z-index: 1;
    padding: 0 1em 1em;
    text-align: center;
    h1,
    h2,
    h4,
    h6,
    p {
      max-width: 960px;
      margin: 0.5em auto 0;
      color: white;
    }
    p {
      margin: 1.5em auto;
    }
    button {
      margin: 1em auto 2em;
    }
  }
  .learn__image {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 2.5em 0 0.5em;
    svg {
      height: 100%;
      path {
        fill: white;
      }
    }
  }
  .learn__transition_waves {
    z-index: -1;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0;
    width: 100vw;
    max-width: 100vw;
    overflow: hidden;
    height: auto;
    margin: -9em 0 -3em;
    svg {
      height: auto;
      width: 150vw;
    }
    svg:first-of-type {
      transform: rotate(-2deg) translateX(-5%) translateY(62%);
      path {
        fill: ${(props) => props.theme.colors.brand[300]};
      }
    }
    svg:last-of-type {
      transform: rotate(2deg) translateX(-7%);
      path {
        fill: white;
      }
    }
    @media (min-width: ${(props) => props.theme.breakpoints[500]}) {
      height: 240px;
      svg {
        display: none;
      }
    }
  }
  .learn__listing {
    position: relative;
    z-index: 1;
    & > section {
      padding: 2.5em;
      border-bottom: 1px solid ${(props) => props.theme.colors.white[700]};
      background: white;
      &.center {
        text-align: center;
      }
      div {
        margin: 0 auto;
      }
      &:first-of-type {
        padding-top: 1em;
      }
    }
  }
`;

// Don't filter featured at query level so we can get all categories
/* eslint no-undef: "off"*/
export const pageQuery = graphql`
  query LearnDirectoryQuery {
    tags: allContentfulLearnArticleTag(filter: { type: { eq: "category" } }, sort: { fields: [value] }) {
      nodes {
        value
        label
        type
        descriptionShort {
          descriptionShort
        }
      }
    }
  }
`;

export default withScrollWatcher(LearnPage);
